






import { Component, Prop, Vue } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import Footer from '../../../../front/shared/organisms/Footer/Footer.vue'
import { FooterProps } from '../../../../front/shared/organisms/Footer/Footer.contracts'

import { translateToFooterProps } from '../../Footer.helpers'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<Default>({
  name: 'Default',
  components: { Footer }
})
export class Default extends Vue {
  @Prop({ type: Object, required: true })
  public readonly data!: AnyObject

  /**
   * Maps `data.content` to `FooterProps`.
   */
  public get toFooterProps (): FooterProps {
    return translateToFooterProps(this.data)
  }
}

export default Default
